import React from 'react';
import {TransitionProps} from '@mui/material/transitions';
import {Slide} from '@mui/material';

const SlideTransition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide
		ref={ref} {...props}
		direction='up'
		children={props.children}
		timeout={300}
	/>;
});

export default SlideTransition;
