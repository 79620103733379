import React from 'react';
import './App.scss';
import './assets/fonts/dish_out.woff2';
import {createTheme, responsiveFontSizes, Snackbar, Theme, ThemeProvider} from '@mui/material';
import {AppState, useAppDispatch, useAppSelector} from './reduxStore';
import InvitationCard from './app/pages/invitationCard/InvitationCard';
import MuiAlert, {AlertProps} from '@mui/material/Alert';
import {setSnackbar} from './appSlice';
import SlideTransition from './app/sharedComponents/SlideTransition';
import {Route, Routes} from 'react-router-dom';

// App
function App() {
    return (
        <ThemeProvider theme={theme}>
            {/** React Router v6 Documentation: https://reactrouter.com/docs/en/v6/getting-started/overview */}
            <Routes>
                <Route
                    path='/'
                    element={<InvitationCard/>}
                />
            </Routes>

            {/** Snackbar */}
            <AppSnackbar/>
        </ThemeProvider>
    );
}

export default App;

// Create an MUI theme instance which inherits from the base theme and has some customisations
const theme: Theme = responsiveFontSizes(createTheme({
    typography: {
        // the order matters
        // MUI will use the first font family by default (if not specified in the component)
        fontFamily: ['EB Garamond', 'Maitree', 'Bona Nova', 'sans-serif'].join(','),
    },
    palette: {
        primary: {
            main: '#DAC6B7',
        },
    },
    components: {
        MuiButton: {
            // global styles override for MuiButton
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiTab: {
            // global styles override for MuiTab
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
}));

// Custom Alert component
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert
        elevation={6}
        ref={ref}
        variant='filled'
        {...props}
    />;
});

// AppSnackbar
const AppSnackbar = () => {
    const state = useAppSelector((state: AppState) => state.app);
    const dispatch = useAppDispatch();

    return (
        <Snackbar
            open={state.snackbar.open}
            autoHideDuration={4000}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            TransitionComponent={SlideTransition}
            onClose={() => dispatch(setSnackbar({
                open: false,
                message: state.snackbar.message,
                severity: state.snackbar.severity,
            }))}
        >
            <Alert
                onClose={() => dispatch(setSnackbar({
                    open: false,
                    message: state.snackbar.message,
                    severity: state.snackbar.severity,
                }))}
                severity={state.snackbar.severity}
                sx={{
                    width: '100%',
                }}
            >
                {state.snackbar.message}
            </Alert>
        </Snackbar>
    );
};
