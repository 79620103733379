import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import Image from 'mui-image';

export default function MobileBankingWish() {
	return (
		<Stack
			width='100%'
			height='100vh'
			color='#FFF'
			sx={{
				backgroundImage: `url(${require('../../../../../assets/images/mobile_banking_wish_bg.jpg')})`,
				backgroundSize: '100% 100%',
				backgroundPosition: 'center',
			}}
		>
			<Box height='30%'/>

			{/** QR codes */}
			<Stack
				height='70%'
			>
				{/** VietinBank QR */}
				<Stack
					alignItems='center'
				>
					<Typography
						variant='h6'
						align='center'
						color='#473E3E'
					>
						DƯƠNG BẢO NHI
					</Typography>
					<Typography
						variant='h6'
						align='center'
						fontWeight={600}
						color='#473E3E'
					>
						VIETINBANK
					</Typography>
					<Typography
						variant='h6'
						align='center'
						fontWeight={600}
						color='#473E3E'
					>
						100874615300
					</Typography>
					<Box
						marginTop='6px'
					>
						<Image
							src={require('../../../../../assets/images/vietinbank_qr.png')}
							width='144px'
							height='auto'
						/>
					</Box>
				</Stack>

				{/** MOMO QR */}
				<Stack
					marginTop='50px'
					alignItems='center'
				>
					<Typography
						variant='h6'
						align='center'
						color='#473E3E'
					>
						DƯƠNG BẢO NHI
					</Typography>
					<Typography
						variant='h6'
						align='center'
						fontWeight={600}
						color='#473E3E'
					>
						MOMO
					</Typography>
					<Typography
						variant='h6'
						align='center'
						fontWeight={600}
						color='#473E3E'
					>
						0707696016
					</Typography>
					<Box
						marginTop='6px'
					>
						<Image
							src={require('../../../../../assets/images/momo_qr.png')}
							width='150px'
							height='auto'
						/>
					</Box>
				</Stack>
			</Stack>
		</Stack>
	);
}
