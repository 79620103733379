import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Dialog,
    DialogContent,
    Grid,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import React from 'react';
import BootstrapDialogTitle from '../../../../sharedComponents/BootstrapDialogTitle';
import SlideTransition from '../../../../sharedComponents/SlideTransition';
import Map from './components/Map';
import {styled} from '@mui/material/styles';

// PageContainer with backdrop
const PageContainer = styled((props: any) =>
    <Stack
        width='100%'
        alignItems='center'
        paddingX='25px'
        paddingY='40px'
        {...props}
    />,
)(({theme}) => ({
    position: 'relative',
    background: `url(${require('../../../../../assets/images/background.jpg')})`,
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
    zIndex: 1,
    '&:before': {
        position: 'absolute',
        top: 8,
        bottom: 0,
        left: 0,
        height: '380px',
        width: '100%',
        zIndex: -1,
        content: '""',
        background: `url(${require('../../../../../assets/images/doraemon_stand_by_me.png')}) no-repeat 100%`,
        backgroundSize: 'contain',
        backgroundPosition: 'bottom',
    },
    '&:after': {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '380px',
        width: '100%',
        zIndex: -1,
        content: '""',
        background: 'rgba(94, 86, 86, .45)',
    },
}));

interface VenuesState {
    venue: null | VenueElement;
}

const initialState: VenuesState = {
    venue: null,
};

export default function Venues() {
    const theme = useTheme();
    const [venue, setVenue] = React.useState(initialState.venue);

    return (
        <PageContainer>
            {/** Section Title */}
            <Stack
                width='100%'
                alignItems='center'
                marginTop='70px'
            >
                <Typography
                    variant='h3'
                    color='#FFF'
                    fontWeight={600}
                >
                    Địa điểm
                </Typography>
                <Stack
                    width='100%'
                    alignItems='center'
                    marginTop='25px'
                >
                    <Box
                        width='80%'
                        height='1px'
                        bgcolor='#FFF'
                    />
                    <Box
                        width='60%'
                        height='1px'
                        bgcolor='#FFF'
                        marginTop='12px'
                    />
                </Stack>
            </Stack>

            {/** Venues */}
            <Stack
                width='100%'
                marginTop='100px'
            >
                <Grid
                    container
                    spacing='20px'
                >
                    {
                        venueElements.map((venue, index) => (
                            <Grid
                                key={index}
                                item
                                sm={12}
                                md={6}
                            >
                                <Card elevation={6}>
                                    <CardMedia
                                        component='img'
                                        height={240}
                                        image={require(`../../../../../assets/images/${venue.img}`)}
                                    />
                                    <CardContent sx={{paddingTop: '25px'}}>
                                        <Typography
                                            variant='h5'
                                            textAlign='center'
                                            color='#706262'
                                            fontWeight={600}
                                        >
                                            {venue.name.toUpperCase()}
                                        </Typography>
                                        <Box height={15}/>
                                        <Typography
                                            variant='h6'
                                            color='#848892'
                                            textAlign='center'
                                            fontWeight={300}
                                        >
                                            {venue.address}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Stack
                                            direction='row'
                                            width='100%'
                                            justifyContent='center'
                                            paddingBottom='25px'
                                        >
                                            <Stack
                                                sx={{position: 'relative'}}
                                            >
                                                <Button
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        borderRadius: '0px',
                                                        color: '#706262',
                                                    }}
                                                    onClick={() => setVenue(venue)}
                                                >
                                                    Xem bản đồ
                                                </Button>
                                                <Box
                                                    width='100%'
                                                    height='1px'
                                                    bgcolor='#706262'
                                                    sx={{
                                                        position: 'absolute',
                                                        bottom: '0px',
                                                    }}
                                                />
                                            </Stack>
                                        </Stack>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
            </Stack>

            {/** Map Dialog */}
            <Dialog
                open={venue !== null}
                fullScreen
                TransitionComponent={SlideTransition}
                onClose={() => setVenue(null)}
            >
                <BootstrapDialogTitle
                    id='dialog-title'
                    onClose={() => setVenue(null)}
                >
                    {venue !== null ? venue.name : ''}
                </BootstrapDialogTitle>
                <DialogContent
                    dividers
                    sx={{padding: 0, margin: 0}}
                >
                    <Box
                        width='100%'
                        height='100%'
                    >
                        <Map
                            lat={venue !== null ? venue.lat : 1}
                            lng={venue !== null ? venue.lng : 1}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </PageContainer>
    );
}

interface VenueElement {
    name: string;
    address: string;
    img: string;
    lat: number;
    lng: number;
}

const venueElements: VenueElement[] = [
    {
        name: 'PHỐ ĐÔI GARDEN',
        address: '118 Đường Bùi Hữu Nghĩa, Bửu Hoà, TP. Biên Hòa, Đồng Nai',
        img: 'pho_doi_garden.jpg',
        lat: 10.925870185663594,
        lng: 106.82286504020696,
    },
    {
        name: 'HƯƠNG PHỐ RESTAURANT',
        address: 'A12 Đ. Phan Văn Trị, Phường 7, Gò Vấp, TP. Hồ Chí Minh',
        img: 'huong_pho.jpeg',
        lat: 10.828801401276989,
        lng: 106.68339132057132,
    },
];
