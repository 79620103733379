import {push, ref} from 'firebase/database';
import {database} from '../../services/firebaseApp';
import {Value} from 'react-quill';

export interface AttendanceNoticeData {
	attend: 'yes' | 'no';
	attendFor: 'bride' | 'groom' | null;
	guestName: string;
	guestRole: 'bride' | 'groom';
	numberOfGuests: number;
	wishes?: Value;
}

/**
 * Add attendance notice to database
 * @param data
 */
export function addAttendNotice(data: AttendanceNoticeData) {
	return new Promise<void>(async (resolve, reject) => {
		try {
			await push(ref(database, 'attendance'), {
				...data,
				wishes: data.wishes === undefined || data.wishes,
				guestNameLower: data.guestName.toLowerCase().trim(), // for look up purpose
				timestamp: new Date().toLocaleString(),
			});
			return resolve();
		} catch (e) {
			return reject(e);
		}
	});
}

/**
 * Calculate time left to the wedding date
 */
export function calculateTimeLeft() {
	return new Promise<{ days: number, hours: number, minutes: number }>((resolve) =>
		setTimeout(() => {
			// UTC time: 05:30 28/12/2022
			// UTC time is 7 hours behind Vietnam time, thus +7
			const difference = +new Date('2022-12-28T05:30:00+07:00') - +new Date();
			let timeLeft: { days: number, hours: number, minutes: number } = {days: 0, hours: 0, minutes: 0};
			if (difference > 0) {
				timeLeft = {
					days: Math.floor(difference / (1000 * 60 * 60 * 24)),
					hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
					minutes: Math.floor((difference / (1000 * 60)) % 60),
				};
			}
			return resolve(timeLeft);
		}, 50));
}
