import {configureStore} from '@reduxjs/toolkit';
import invitationCardReducer from './app/pages/invitationCard/invitationCardSlice';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import appSliceReducer from './appSlice';

// Global store
const store = configureStore({
	reducer: {
		app: appSliceReducer,
		invitationCard: invitationCardReducer,
	},
});

// AppState type
export type AppState = ReturnType<typeof store.getState>;
// AppDispatch type
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export default store;
