import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {addAttendNotice, AttendanceNoticeData, calculateTimeLeft} from './invitationCardAPI';
import {setSnackbar} from '../../../appSlice';
import {Value} from 'react-quill';

export interface InvitationCardState {
	attendNotice: {
		attend: 'yes' | 'no';
		guestName: string;
		guestRole: 'none' | 'bride' | 'groom';
		numberOfGuests: number;
		wishes?: Value;
		attendNoticeStatus: 'idle' | 'pending';
	},
	timeLeft: {
		days: number;
		hours: number;
		minutes: number;
	},
	selectedStoryTab: number;
}

const initialState: InvitationCardState = {
	attendNotice: {
		attend: 'yes',
		guestName: '',
		guestRole: 'none',
		numberOfGuests: -1,
		wishes: '',
		attendNoticeStatus: 'idle',
	},
	timeLeft: {
		days: 0,
		hours: 0,
		minutes: 0,
	},
	selectedStoryTab: 0,
};

export const invitationCardSlice = createSlice({
	name: 'invitationCard',
	initialState,
	// Define reducers and associated actions
	reducers: {
		setAttend: (state, action: PayloadAction<'yes' | 'no'>) => {
			state.attendNotice.attend = action.payload;
			state.attendNotice.numberOfGuests = action.payload === 'no' ? 0 : -1;
		},
		setGuestName: (state, action: PayloadAction<string>) => {
			state.attendNotice.guestName = action.payload;
		},
		setGuestRole: (state, action: PayloadAction<'none' | 'bride' | 'groom'>) => {
			state.attendNotice.guestRole = action.payload;
		},
		setNumberOfGuests: (state, action: PayloadAction<number>) => {
			state.attendNotice.numberOfGuests = action.payload;
		},
		setWishes: (state, action: PayloadAction<Value>) => {
			state.attendNotice.wishes = action.payload;
		},
		resetAttendNotice: (state) => {
			state.attendNotice = {
				...initialState.attendNotice,
			}
		},
	},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: (builder) => {
		builder
			.addCase(sendAttendNotice.pending, (state, action) => {
				state.attendNotice.attendNoticeStatus = 'pending';
			})
			.addCase(sendAttendNotice.fulfilled, (state, action) => Object.assign({
				...state,
				attendNotice: {
					...initialState.attendNotice,
				},
			}))
			.addCase(sendAttendNotice.rejected, (state, action) => Object.assign({
				...state,
				attendNotice: {
					...state.attendNotice,
					attendNoticeStatus: 'idle',
				},
			}))
			.addCase(getTimeLeft.fulfilled, (state, action) => Object.assign({
				...state,
				timeLeft: {
					days: action.payload.days,
					hours: action.payload.hours,
					minutes: action.payload.minutes,
				},
			}));
	},
});

// Actions
export const {
	setAttend,
	setGuestName,
	setGuestRole,
	setNumberOfGuests,
	setWishes,
	resetAttendNotice,
} = invitationCardSlice.actions;

export default invitationCardSlice.reducer;

export const sendAttendNotice = createAsyncThunk(
	'invitationCard/sendAttendNotice',
	async (data: AttendanceNoticeData, thunkAPI) => {
		try {
			await addAttendNotice(data);
			thunkAPI.dispatch(
				setSnackbar({open: true, message: 'Cám ơn bạn đã phản hồi', severity: 'success'}),
			);
		} catch (e) {
			thunkAPI.dispatch(
				setSnackbar({open: true, message: 'Lỗi đã xảy ra. Vui lòng thử lại', severity: 'error'}),
			);
			// the API throws an error, we need to call `rejectWithValue` so that `sendAttendNotice.rejected` can be
			// called in `extraReducers`
			return thunkAPI.rejectWithValue(null);
		}
	},
);

export const getTimeLeft = createAsyncThunk(
	'invitationCard/getTimeLeft',
	async () => {
		return await calculateTimeLeft();
	},
);
