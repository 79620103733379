import React from 'react';
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '100%',
};

function Map(props: { lat: number, lng: number }) {
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map: any) {
        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null);
    }, []);

    const center = {
        lat: props.lat,
        lng: props.lng,
    };

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={18}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <Marker
                position={center}
            />
        </GoogleMap>
    ) : <></>
}

export default React.memo(Map);
