import {initializeApp} from 'firebase/app';
import {getDatabase} from 'firebase/database';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    authDomain: "wedding-web-app-356614.firebaseapp.com",
    databaseURL: "https://wedding-web-app-356614-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "wedding-web-app-356614",
    storageBucket: "wedding-web-app-356614.appspot.com",
    messagingSenderId: "705240511420",
    appId: "1:705240511420:web:8a861375d0a0dda0ec71b9",
    measurementId: "G-WVR80NFN88"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;

// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app);
