import {
    Box,
    ButtonBase,
    Dialog,
    DialogActions,
    DialogContent,
    Fab,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React from 'react';
import Image from 'mui-image';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // required styles for Carousel
import {Carousel} from 'react-responsive-carousel';
import SlideTransition from '../../../../sharedComponents/SlideTransition';
import {Close} from '@mui/icons-material';
import BootstrapDialogTitle from '../../../../sharedComponents/BootstrapDialogTitle';
import Surprise from '../../../surprise/Surprise';

interface StoryDialogState {
    storyDialogContent: null | StoryDialogContent;
}

interface StoryDialogContent {
    backgroundImage: string;
    heading1: string;
    heading2: string;
    heading3: string;
    images: string[];
    story: string;
}

const initialState: StoryDialogState = {
    storyDialogContent: null,
};

export default function MainCover() {
    const theme = useTheme();
    const smBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

    const [storyDialogContent, setStoryDialogContent] = React.useState(initialState.storyDialogContent);

    const SURPRISE_TRIGGER_27 = '27/12/2022';
    const MAX_FIRST_COUNT_27 = 1;
    const MAX_SECOND_COUNT_27 = 5;
    const MAX_THIRD_COUNT_27 = 8;
    const [firstCount27, setFirstCount27] = React.useState(0);
    const [secondCount27, setSecondCount27] = React.useState(0);
    const [thirdCount27, setThirdCount27] = React.useState(0);
    const [surprise27, setSurprise27] = React.useState(false);

    const SURPRISE_TRIGGER_28 = '28/12/2022';
    const MAX_FIRST_COUNT_28 = 1;
    const MAX_SECOND_COUNT_28 = 7;
    const MAX_THIRD_COUNT_28 = 6;
    const [firstCount28, setFirstCount28] = React.useState(0);
    const [secondCount28, setSecondCount28] = React.useState(0);
    const [thirdCount28, setThirdCount28] = React.useState(0);
    const [surprise28, setSurprise28] = React.useState(false);

    return (
        <Stack
            width='100%'
            height='100vh'
            alignItems='center'
            justifyContent='space-between'
            color='#FFF'
            sx={{
                position: 'relative',
                backgroundImage: `url(${require('../../../../../assets/images/main_cover_bg.jpg')})`,
                backgroundSize: '100% 100%',
                backgroundPosition: 'center',
            }}
        >
            {/** Headings */}
            <Stack
                width='100%'
                alignItems='center'
                marginTop='70px'
            >
                <Typography
                    variant='h2'
                    align='center'
                    fontFamily='Yellowtail'
                    color='#478AB4'
                >
                    The Journey Of
                </Typography>
                <Box height={2}/>
                <Typography
                    variant='h1'
                    align='center'
                    color='#473E3E'
                    fontWeight={700}
                >
                    N&K
                </Typography>
                <Typography
                    variant='h1'
                    align='center'
                    color='#473E3E'
                    fontWeight={700}
                    marginTop={-2}
                >
                    WEDDING
                </Typography>
                <Box height={2}/>
                <Typography
                    variant='h5'
                    align='center'
                    color='#8C6C57'
                    fontWeight={600}
                >
                    2016 - 2022
                </Typography>
            </Stack>

            {/** Links to other content */}
            <Stack
                direction='row'
                spacing={smBreakpoint ? 1.5 : 4}
                paddingX='15px'
                paddingY='25px'
                justifyContent='center'
                sx={{
                    overflow: 'hidden',
                }}
            >
                <Box width='112px'>
                    <ButtonBase
                        onClick={() => {
                            setStoryDialogContent(storyDialogContents[0]);
                            // reset if == 1
                            if (firstCount27 === MAX_FIRST_COUNT_27) {
                                setFirstCount27(0);
                            } else {
                                setFirstCount27(firstCount27 + 1);
                            }
                        }}
                    >
                        <Image
                            src={require('../../../../../assets/images/main_cover_button_1.png')}
                            fit='contain'
                            width='100%'
                            height='auto'
                        />
                    </ButtonBase>
                </Box>
                <Box width='112px'>
                    <ButtonBase
                        onClick={() => {
                            if (firstCount27 === MAX_FIRST_COUNT_27 && secondCount27 === MAX_SECOND_COUNT_27 && thirdCount27 === MAX_THIRD_COUNT_27) {
                                if (getDate() === SURPRISE_TRIGGER_27) {
                                    setSurprise27(true);
                                    return;
                                }
                            }
                            setStoryDialogContent(storyDialogContents[1]);
                            // reset if == 5
                            if (secondCount27 === MAX_SECOND_COUNT_27) {
                                setSecondCount27(0);
                            } else {
                                setSecondCount27(secondCount27 + 1);
                            }
                        }}
                    >
                        <Image src={require('../../../../../assets/images/main_cover_button_2.png')}/>
                    </ButtonBase>
                </Box>
                <Box width='112px'>
                    <ButtonBase
                        onClick={() => {
                            setStoryDialogContent(storyDialogContents[2]);
                            // reset if == 8
                            if (thirdCount27 === MAX_THIRD_COUNT_27) {
                                setThirdCount27(0);
                            } else {
                                setThirdCount27(thirdCount27 + 1);
                            }
                        }}
                    >
                        <Image src={require('../../../../../assets/images/main_cover_button_3.png')}/>
                    </ButtonBase>
                </Box>
            </Stack>

            {/** Stand By Me 2 Image */}
            <Box
                width='100%'
                sx={{
                    overflowY: 'hidden',
                }}
            >
                <Image
                    src={require('../../../../../assets/images/doraemon_stand_by_me.png')}
                    width='100%'
                    duration={3000}
                    shift='top'
                    fit='contain'
                />
            </Box>

            {/** Story Content Dialog */}
            <Dialog
                open={storyDialogContent !== null}
                fullScreen={smBreakpoint}
                maxWidth='sm'
                TransitionComponent={SlideTransition}
                keepMounted
                onClose={() => {
                    if (storyDialogContent != null && storyDialogContent.heading1 === 'ENGAGEMENT' && firstCount28 === MAX_FIRST_COUNT_28 && secondCount28 === MAX_SECOND_COUNT_28 && thirdCount28 === MAX_THIRD_COUNT_28) {
                        if (getDate() === SURPRISE_TRIGGER_28) {
                            setSurprise28(true);
                            setStoryDialogContent(null);
                            return;
                        }
                    }
                    setStoryDialogContent(null);
                }}
                PaperProps={{
                    sx: {
                        minHeight: '100vh',
                    },
                }}
            >
                <DialogContent
                    sx={{
                        padding: 0,
                        margin: 0,
                        backgroundImage: storyDialogContent === null ? '' : `url(${require(`../../../../../assets/images/${storyDialogContent.backgroundImage}`)})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        paddingBottom: '100px',
                    }}
                >
                    {/** Dialog Content */}
                    <Stack>
                        {/** Headings */}
                        <Stack
                            width='60%'
                            marginTop='100px'
                        >
                            <Typography
                                align='center'
                                color='#478AB4'
                            >
                                {storyDialogContent === null ? '' : storyDialogContent.heading1}
                            </Typography>
                            <Typography
                                align='center'
                                color='#478AB4'
                            >
                                {storyDialogContent === null ? '' : storyDialogContent.heading2}
                            </Typography>
                            <Typography
                                variant='h5'
                                align='center'
                                color='#473E3E'
                                fontWeight={600}
                            >
                                {storyDialogContent === null ? '' : storyDialogContent.heading3}
                            </Typography>
                        </Stack>

                        {/** Images Box */}
                        <Box
                            width='100%'
                            marginTop='100px'
                        >
                            {
                                storyDialogContent === null ? <Box
                                        width='100%'
                                        height={smBreakpoint ? '260px' : '460px'}
                                    >
                                        <Image
                                            src={require('../../../../../assets/images/placeholder.png')}
                                            duration={2000}
                                            fit='cover'
                                        />
                                    </Box>
                                    : <Carousel
                                        showIndicators={true}
                                        showThumbs={false}
                                        showArrows={true}
                                        showStatus={false}
                                    >
                                        {storyDialogContent.images.map((image, index) => (
                                            <Box
                                                key={index}
                                                width='100%'
                                                height={smBreakpoint ? '260px' : '460px'}
                                                onClick={() => {
                                                    if (storyDialogContent.heading1 === 'ENGAGEMENT') {
                                                        if (index === 0) {
                                                            if (firstCount28 === MAX_FIRST_COUNT_28) {
                                                                setFirstCount28(0);
                                                            } else {
                                                                setFirstCount28(MAX_FIRST_COUNT_28);
                                                            }
                                                            return;
                                                        }
                                                        if (index === 6) {
                                                            if (secondCount28 === MAX_SECOND_COUNT_28) {
                                                                setSecondCount28(0);
                                                            } else {
                                                                setSecondCount28(MAX_SECOND_COUNT_28);
                                                            }
                                                            return;
                                                        }
                                                        if (index === 5) {
                                                            if (thirdCount28 === MAX_THIRD_COUNT_28) {
                                                                setThirdCount28(0);
                                                            } else {
                                                                setThirdCount28(MAX_THIRD_COUNT_28);
                                                            }
                                                            return;
                                                        }
                                                        setFirstCount28(0);
                                                        setSecondCount28(0);
                                                        setThirdCount28(0);
                                                    }
                                                }}
                                            >
                                                <Image
                                                    src={image}
                                                    duration={2000}
                                                    fit='cover'
                                                />
                                            </Box>
                                        ))}
                                    </Carousel>
                            }
                        </Box>

                        {/** Story Content */}
                        <Box
                            width='100%'
                            marginTop='20px'
                            paddingX='20px'
                        >
                            <Typography
                                align='justify'
                                variant='h6'
                            >
                                {storyDialogContent === null ? '' : storyDialogContent.story}
                            </Typography>
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions
                    sx={{
                        margin: 0,
                        padding: 0,
                    }}
                >
                    {/** Close Button */}
                    <Stack
                        width='100%'
                        alignItems='center'
                    >
                        <Fab
                            size='medium'
                            onClick={() => {
                                if (storyDialogContent != null && storyDialogContent.heading1 === 'ENGAGEMENT' && firstCount28 === MAX_FIRST_COUNT_28 && secondCount28 === MAX_SECOND_COUNT_28 && thirdCount28 === MAX_THIRD_COUNT_28) {
                                    if (getDate() === SURPRISE_TRIGGER_28) {
                                        setSurprise28(true);
                                        setStoryDialogContent(null);
                                        return;
                                    }
                                }
                                setStoryDialogContent(null);
                            }}
                            sx={{
                                margin: 0,
                                top: 'auto',
                                right: 20,
                                bottom: 30,
                                left: 'auto',
                                position: 'fixed',
                            }}
                        >
                            <Close/>
                        </Fab>
                    </Stack>
                </DialogActions>
            </Dialog>

            {/** Surprise dialog */}
            <Dialog
                open={(surprise27 && firstCount27 === MAX_FIRST_COUNT_27 && secondCount27 === MAX_SECOND_COUNT_27 && thirdCount27 === MAX_THIRD_COUNT_27) || (surprise28 && firstCount28 === MAX_FIRST_COUNT_28 && secondCount28 === MAX_SECOND_COUNT_28 && thirdCount28 === MAX_THIRD_COUNT_28)}
                fullScreen
                TransitionComponent={SlideTransition}
                onClose={() => {
                    setSurprise27(false);
                    setSurprise28(false);
                }}
            >
                <BootstrapDialogTitle
                    id='dialog-title'
                    onClose={() => {
                        setSurprise27(false);
                        setSurprise28(false);
                    }}
                    iconColor='white'
                    sx={{
                        backgroundColor: 'black',
                        color: 'white',
                    }}
                >
                    Bất ngờ nè !!!
                </BootstrapDialogTitle>
                <DialogContent
                    dividers
                    sx={{padding: 0, margin: 0}}
                >
                    <Surprise/>
                </DialogContent>
            </Dialog>
        </Stack>
    );
}

const storyDialogContents: StoryDialogContent[] = [
    {
        backgroundImage: 'first_met_bg.jpg',
        heading1: 'FIRST TIME',
        heading2: 'Ngày 28 tháng 01 năm 2016',
        heading3: 'LẦN ĐẦU GẶP NHAU',
        images: [
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Ffirst-met%2F1.jpg?alt=media&token=7e51d7c3-a689-4682-b78f-3ea53672af0a',
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Ffirst-met%2F2.jpg?alt=media&token=1e282bb5-eaab-4f6f-92b9-c7d22e1b3796',
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Ffirst-met%2F3.jpg?alt=media&token=c3786b4f-625c-48c2-ad9f-9ea6cc258735',
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Ffirst-met%2F4.jpg?alt=media&token=a33f6a85-881f-40d1-93e0-cdb74e79d059',
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Ffirst-met%2F5.jpg?alt=media&token=00754a1e-cdf3-4fa2-b5e6-85a553ef3c27',
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Ffirst-met%2F6.jpg?alt=media&token=0b7c0f0b-1d95-496e-9017-3643d9616472',
        ],
        story: 'Tham gia chiến dịch "Xuân Tình Nguyện 2016" là lần đầu tiên chúng mình gặp nhau, chính "nụ cười chết người" ' +
            'của Nhi đã khiến Khoa ngây ngất. Cùng team Văn Thể Mỹ, cùng nhau đóng kịch nhưng Nhi lại không chút ấn tượng về Khoa, ' +
            'mãi đến khi kết thúc chuyến đi thì Nhi phát bệnh và Khoa là người đầu tiên hỏi thăm, quan tâm Nhi từ xa... ' +
            'Tình bạn đã bắt đầu!',
    },
    {
        backgroundImage: 'dating_bg.jpg',
        heading1: 'FIRST DATE',
        heading2: 'Ngày 15 tháng 08 năm 2016',
        heading3: 'THỜI GIAN HẸN HÒ',
        images: [
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Fdating%2F1.jpg?alt=media&token=83a43790-56a5-447d-ac94-e7660dba47bb',
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Fdating%2F2.jpg?alt=media&token=fb875b9b-1152-4484-9485-d0167197d035',
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Fdating%2F3.jpg?alt=media&token=3b2dddda-ede0-400e-b5d1-cdf5f6a6232a',
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Fdating%2F4.jpg?alt=media&token=d266fda1-7b42-4068-9c32-8a2593c771a2',
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Fdating%2F5.jpg?alt=media&token=820ed044-414b-469a-809c-cd8a80e9e8f1',
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Fdating%2F6.jpg?alt=media&token=0247ca65-15c4-4167-9f2e-2da6e1bf9ef5',
        ],
        story: 'Từ tình bạn trở thành tình "Thầy Trò", Khoa dùng kiến thức để tung hoả mù tiếp cận để chúng mình nói' +
            ' chuyện nhiều hơn và Khoa cũng hiểu hơn về cô "siêu quậy". Sau gần 1 năm tung chiêu, Khoa đã chinh phục được Nhi. ' +
            'Khoảnh khắc tụi mình hẹn hò cũng là ngày tụi mình Yêu Xa, đã rất nhiều chuyện yêu đương mâu thuẫn có vui buồn ' +
            'xen lẫn đã xảy ra, và chúng mình cứ như thế mà yêu nhau.',
    },
    {
        backgroundImage: 'engagement_bg.jpg',
        heading1: 'ENGAGEMENT',
        heading2: 'Ngày 17 tháng 06 năm 2022',
        heading3: 'ĐÍNH ƯỚC DÀI LÂU',
        images: [
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Fengagement%2F1.jpg?alt=media&token=9fc5140f-f6e0-4cbb-a3ee-4b86654fd6d3',
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Fengagement%2F2.jpg?alt=media&token=cb94b1f2-bdc4-4262-88ff-9b54269389f6',
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Fengagement%2F3.jpg?alt=media&token=936e1c53-268d-4b38-b7b3-ff1b45722d3b',
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Fengagement%2F4.jpg?alt=media&token=06f6b222-900c-4352-8158-e7d7d3103309',
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Fengagement%2F5.jpg?alt=media&token=c2f1459c-69de-45bd-8ac0-f6dd349531f2',
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Fengagement%2F6.jpg?alt=media&token=a074f28d-436d-4676-972d-61db84b857bb',
            'https://firebasestorage.googleapis.com/v0/b/wedding-web-app-356614.appspot.com/o/story%2Fengagement%2F7.jpg?alt=media&token=670dfa4b-111b-476b-87e2-0debde229ee8',
        ],
        story: 'Sau 6 năm yêu xa và trải qua rất nhiều thăng trầm, đã có lúc tụi mình tưởng chừng như... tụi mình' +
            ' khép lại tình yêu "xa" này bằng về chung một nhà "cho gần" 17 - 06 - 2022 là 1 cột mốc, là buổi Đính' +
            ' Hôn hoành tráng. Trích lời Khoa nói: "Khoa thật may mắn vì dù ở xa quê nhà, nhưng Khoa vẫn luôn có 1 ' +
            'người bên cạnh để cổ vũ, động viên Khoa những lúc khó khăn".',
    },
];

const getDate = () => {
    const newDate = new Date();
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const day = newDate.getDate();

    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
}
