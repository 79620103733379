import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import {Provider} from 'react-redux';
import store from './reduxStore';
import {BrowserRouter} from 'react-router-dom';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
	<React.StrictMode>
		{/** <BrowserRouter> is the recommended interface for running React Router in a web browser.
		 A <BrowserRouter> stores the current location in the browser's address bar using clean URLs and navigates
		 using the browser's built-in history stack. */}
		<BrowserRouter>
			<Provider store={store}>
				<App/>
			</Provider>
		</BrowserRouter>
	</React.StrictMode>,
);

reportWebVitals();
