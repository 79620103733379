import React from 'react';
import {DialogTitle, IconButton} from '@mui/material';
import {Close} from '@mui/icons-material';

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
	iconColor?: string;
	[other: string]: any;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
	const {children, onClose, iconColor, ...other} = props;

	return (
		<DialogTitle
			sx={{m: 0, p: 2}}
			typography='h5'
			fontFamily='Bona Nova'
			{...other}
		>
			{children}
			{onClose ? (
				<IconButton
					aria-label='close'
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: iconColor === undefined ? (theme) => theme.palette.grey[600] : iconColor,
					}}
				>
					<Close/>
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

export default BootstrapDialogTitle;
