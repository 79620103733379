import React from 'react';
import {Stack} from '@mui/material';

export default function ThankYou() {
	return (
		<Stack
			width='100%'
			height='100vh'
			color='#FFF'
			sx={{
				backgroundImage: `url(${require('../../../../../assets/images/thank_you_bg.jpg')})`,
				backgroundSize: '100% 100%',
				backgroundPosition: 'center',
			}}
		/>
	);
}
