import {Stack} from '@mui/material';
import React from 'react';

export default function EndNote() {
	return (
		<Stack
			width='100%'
			height='100vh'
			color='#FFF'
			sx={{
				backgroundImage: `url(${require('../../../../../assets/images/endnote_bg.jpg')})`,
				backgroundSize: '100% 100%',
				backgroundPosition: 'center',
			}}
		/>
	);
}
