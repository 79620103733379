import React from 'react';
import {
	Box,
	ButtonBase,
	Dialog,
	DialogActions,
	DialogContent,
	Fab,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import Image from 'mui-image';
import SlideTransition from '../../../../sharedComponents/SlideTransition';
import {Close} from '@mui/icons-material';

interface InvitationDialogState {
	invitationDialog: 'Bride' | 'Groom' | null;
}

const initialState: InvitationDialogState = {
	invitationDialog: null,
};

export default function Invitation() {
	const theme = useTheme();
	const smBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

	const [invitationDialog, setInvitationDialog] = React.useState(initialState.invitationDialog);

	return (
		<Stack
			width='100%'
			height='100vh'
			alignItems='center'
			color='#FFF'
			sx={{
				backgroundImage: `url(${require('../../../../../assets/images/wedding_invitation_bg.jpg')})`,
				backgroundSize: '100% 100%',
				backgroundPosition: 'center',
			}}
		>
			<Box height='45%'/>

			{/** Content */}
			<Stack
				width='100%'
				height='55%'
			>
				{/** Text */}
				<Box
					width='100%'
					paddingX='15px'
				>
					<Typography
						variant='h5'
						align='center'
						color='#8C6C57'
					>
						Trân trọng báo tin và kính mời quý khách
					</Typography>
				</Box>

				{/** Buttons */}
				<Stack
					width='100%'
					direction='row'
					marginTop='40px'
					paddingX='50px'
					justifyContent='center'
					spacing={7}
				>
					<Box width='112px'>
						<ButtonBase
							onClick={() => setInvitationDialog('Bride')}
						>
							<Image src={require('../../../../../assets/images/wedding_invitation_button_1.png')}/>
						</ButtonBase>
					</Box>
					<Box width='112px'>
						<ButtonBase
							onClick={() => setInvitationDialog('Groom')}
						>
							<Image src={require('../../../../../assets/images/wedding_invitation_button_2.png')}/>
						</ButtonBase>
					</Box>
				</Stack>
			</Stack>

			{/** Invitation Dialog */}
			<Dialog
				open={invitationDialog !== null}
				fullScreen={smBreakpoint}
				maxWidth='sm'
				TransitionComponent={SlideTransition}
				keepMounted
				onClose={() => setInvitationDialog(null)}
				PaperProps={{
					sx: {
						minHeight: '100vh',
					},
				}}
			>
				<DialogContent
					sx={{
						padding: 0,
						margin: 0,
						height: '100%',
						backgroundImage: `url(${require('../../../../../assets/images/wedding_invitation_bg_1.jpg')})`,
						backgroundPosition: 'center',
						backgroundSize: '100% 100%',
						paddingTop: '170px',
						paddingBottom: '100px',
						paddingX: '10px',
					}}
				>
					{/** Dialog Content */}
					<Stack>
						{/** Parents' names */}
						<Stack
							direction='row'
						>
							<Stack
								width='100%'
								alignItems='center'
							>
								<Typography
									variant='h6'
									align='center'
									fontWeight={700}
									color='#4A642D'
								>
									Ông/Bà
									<br/>
									{invitationDialog === 'Bride' ? 'Dương Văn Nhân' : 'Phùng Trác Công Anh'}
									<br/>
									{invitationDialog === 'Bride' ? 'Trần Thị Thanh Tuyền' : 'Bùi Thị Kim Thoa'}
								</Typography>
								<Box height='5px'/>
								<Typography
									variant='body1'
									align='center'
									color='#4A642D'
								>
									{invitationDialog === 'Bride'
										? 'I22, N4, Bửu Long, Biên Hoà, Đồng Nai'
										: '423/60/7, Tô Ký, Quận 12, TP. HCM'}
								</Typography>
							</Stack>
							<Box width='30px'/>
							<Stack
								width='100%'
								alignItems='center'
							>
								<Typography
									variant='h6'
									align='center'
									fontWeight={700}
									color='#4A642D'
								>
									Ông/Bà
									<br/>
									{invitationDialog === 'Bride' ? 'Phùng Trác Công Anh' : 'Dương Văn Nhân'}
									<br/>
									{invitationDialog === 'Bride' ? 'Bùi Thị Kim Thoa' : 'Trần Thị Thanh Tuyền'}
								</Typography>
								<Box height='5px'/>
								<Typography
									variant='body1'
									align='center'
									color='#4A642D'
								>
									{invitationDialog === 'Bride'
										? '423/60/7, Tô Ký, Quận 12, TP. HCM'
										: 'I22, N4, Bửu Long, Biên Hoà, Đồng Nai'}
								</Typography>
							</Stack>
						</Stack>

						{/** Invitation text */}
						<Stack
							marginY='30px'
						>
							<Typography
								variant='h5'
								align='center'
								color='#4A642D'
							>
								Trân trọng báo tin
								{invitationDialog === 'Bride' ? ' LỄ VU QUY' : ' LỄ THÀNH HÔN'}
								<br/>
								của con chúng tôi
							</Typography>
						</Stack>

						{/** Bride's and Groom's names */}
						<Stack
							width='100%'
							alignItems='center'
						>
							<Stack>
								<Typography
									variant='h3'
									align='center'
									fontWeight={700}
									color='#706262'
								>
									{invitationDialog === 'Bride' ? 'DƯƠNG BẢO NHI' : 'PHÙNG ĐĂNG KHOA'}
								</Typography>
								<Typography
									variant='h6'
									align='center'
									color='#706262'
								>
									{invitationDialog === 'Bride' ? 'Trưởng Nữ' : 'Trưởng Nam'}
								</Typography>
							</Stack>
							<Typography
								variant='h3'
								align='center'
								fontWeight={700}
								color='#706262'
							>
								&
							</Typography>
							<Stack>
								<Typography
									variant='h3'
									align='center'
									fontWeight={700}
									color='#706262'
								>
									{invitationDialog === 'Bride' ? 'PHÙNG ĐĂNG KHOA' : 'DƯƠNG BẢO NHI'}
								</Typography>
								<Typography
									variant='h6'
									align='center'
									color='#706262'
								>
									{invitationDialog === 'Bride' ? 'Trưởng Nam' : 'Trưởng Nữ'}
								</Typography>
							</Stack>
						</Stack>

						{/** Date and time */}
						<Stack
							width='100%'
							alignItems='center'
							marginTop='30px'
						>
							<Typography
								variant='h5'
								align='center'
								color='#4A642D'
							>
								{invitationDialog === 'Bride'
									? 'Buổi lễ được cử hành tại tư gia' : 'Buổi lễ được cử hành tại tư gia'}
								<br/>
								{invitationDialog === 'Bride'
									? 'I22, N4, KP1, P. Bửu Long, Biên Hoà' : '423/60/7, Tô Ký, Quận 12, TP. HCM'}
								<br/>
								{invitationDialog === 'Bride'
									? 'Vào lúc 07:00 ngày 28.12.2022' : 'Vào lúc 10:00 ngày 28.12.2022'}
								<br/>
								{invitationDialog === 'Bride'
									? '(Nhằm ngày 06.12.2022 âm lịch)' : '(Nhằm ngày 06.12.2022 âm lịch)'}
							</Typography>
						</Stack>
					</Stack>
				</DialogContent>
				<DialogActions
					sx={{
						margin: 0,
						padding: 0,
					}}
				>
					{/** Close Button */}
					<Fab
						size='medium'
						onClick={() => setInvitationDialog(null)}
						sx={{
							margin: 0,
							top: 'auto',
							right: 20,
							bottom: 30,
							left: 'auto',
							position: 'fixed',
						}}
					>
						<Close/>
					</Fab>
				</DialogActions>
			</Dialog>
		</Stack>
	);
}
