import React from 'react';
import {Backdrop, Box, Stack, useMediaQuery, useTheme} from '@mui/material';
import Image from 'mui-image';

function Surprise() {
    const theme = useTheme();
    const smBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

    const [counter, setCounter] = React.useState(5);

    React.useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    return <Stack
        height='100%'
        justifyContent='center'
        bgcolor='black'
    >
        {
            counter > 0
                ? null
                // eslint-disable-next-line jsx-a11y/iframe-has-title
                : <iframe src="https://drive.google.com/file/d/1wy4nkxsBbGRV_WzRQgiONVoEH9AnLxew/preview" width="100%"
                          height="100%" allow="autoplay *; fullscreen *"></iframe>
        }
        <Backdrop
            open={counter > 0}
            transitionDuration={1000}
            sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
            }}
        >
            <Box
                width={smBreakpoint ? '210px' : '304px'}
                height={smBreakpoint ? '210px' : '304px'}
            >
                <Image src={require('../../../assets/images/doraemon_surprise.gif')} fit='scale-down'/>
            </Box>
        </Backdrop>
    </Stack>;
}

export default Surprise;
