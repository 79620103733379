import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface SnackbarProps {
	open: boolean;
	message: null | string;
	severity?: 'success' | 'error' | 'info' | 'warning';
}

export interface GlobalState {
	snackbar: SnackbarProps;
}

const initialState: GlobalState = {
	snackbar: {
		open: false,
		message: null,
	},
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setSnackbar: (state, action: PayloadAction<SnackbarProps>) => Object.assign({
			...state,
			snackbar: action.payload,
		}),
	},
});

export const {setSnackbar} = appSlice.actions;

export default appSlice.reducer;
