import React from 'react';
import {
    alpha,
    Box,
    Button,
    ButtonBase,
    Dialog,
    DialogActions,
    DialogContent,
    Fab,
    FormControl,
    InputBase,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Image from 'mui-image';
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from '@mui/lab';
import {Brightness7, Close, Handshake, Restaurant} from '@mui/icons-material';
import {
    resetAttendNotice,
    sendAttendNotice,
    setAttend,
    setGuestName,
    setGuestRole,
    setNumberOfGuests,
} from '../../invitationCardSlice';
import {styled} from '@mui/material/styles';
import {AppState, useAppDispatch, useAppSelector} from '../../../../../reduxStore';
import SlideTransition from '../../../../sharedComponents/SlideTransition';

interface CalendarInviteState {
    calendarInviteContent: CalendarInviteContent | null;
}

interface CalendarInviteContent {
    inviteFor: 'bride' | 'groom';
    dateMonth: string;
    year: string;
    venue: string;
    venueDetail: string;
    venueAddress: string;
    inviteTime: string;
    inviteTime1: string;
    dressCode?: string[];
    timeline: {
        time: string;
        activity: string;
        icon: React.ReactNode;
    }[];
}

const initialCalendarInviteState: CalendarInviteState = {
    calendarInviteContent: null,
}

export default function CalendarInvite() {
    const state = useAppSelector((state: AppState) => state.invitationCard);
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const smBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

    const [calendarInviteContent, setCalendarInviteContent] = React.useState(initialCalendarInviteState.calendarInviteContent);

    return (
        <Stack
            width='100%'
            height='100vh'
            alignItems='center'
            color='#FFF'
            sx={{
                backgroundImage: `url(${require('../../../../../assets/images/calendar_invite_bg.jpg')})`,
                backgroundSize: '100% 100%',
                backgroundPosition: 'center',
            }}
        >
            <Box height='28%'/>

            {/** Buttons */}
            <Stack
                width='100%'
                height='72%'
                direction='row'
                paddingX='50px'
                justifyContent='center'
                spacing={7}
            >
                <Box width='112px'>
                    <ButtonBase
                        onClick={() => setCalendarInviteContent(calendarInviteContents[0])}
                    >
                        <Image src={require('../../../../../assets/images/calendar_invite_button_1.png')}/>
                    </ButtonBase>
                </Box>
                <Box width='112px'>
                    <ButtonBase
                        onClick={() => setCalendarInviteContent(calendarInviteContents[1])}
                    >
                        <Image src={require('../../../../../assets/images/calendar_invite_button_2.png')}/>
                    </ButtonBase>
                </Box>
            </Stack>

            <Dialog
                open={calendarInviteContent !== null}
                TransitionComponent={SlideTransition}
                onClose={() => {
                    setCalendarInviteContent(null);
                    dispatch(resetAttendNotice());
                }}
                fullScreen={smBreakpoint}
                maxWidth='sm'
                PaperProps={{
                    sx: {
                        minHeight: '100vh',
                    },
                }}
            >
                <DialogContent
                    sx={{
                        padding: 0,
                        margin: 0,
                        height: '100%',
                        backgroundImage: `url(${require('../../../../../assets/images/background.jpg')})`,
                        backgroundPosition: 'center',
                        backgroundSize: '100% 100%',
                        paddingY: '100px',
                        paddingX: '25px',
                    }}
                >
                    <Stack
                        width='100%'
                    >
                        {/** Invitation */}
                        <Stack
                            width='100%'
                        >
                            {/** Date */}
                            <Stack
                                width='100%'
                                alignItems='center'
                            >
                                <Typography
                                    variant='h1'
                                    align='center'
                                    color='#473E3E'
                                >
                                    {calendarInviteContent === null ? '' : calendarInviteContent.dateMonth}
                                </Typography>
                                <Box
                                    width='60px'
                                    height='1px'
                                    bgcolor='#7A7672'
                                />
                                <Typography
                                    variant='h1'
                                    align='center'
                                    color='#473E3E'
                                >
                                    {calendarInviteContent === null ? '' : calendarInviteContent.year}
                                </Typography>
                            </Stack>

                            {/** Texts */}
                            <Stack
                                marginTop='30px'
                            >
                                <Typography
                                    fontWeight='bold'
                                    fontStyle='italic'
                                >
                                    Trân trọng kính mời,
                                </Typography>
                                <Typography
                                    fontStyle='italic'
                                >
                                    Đến chung vui buổi tiệc, mừng lễ thành hôn của con chúng tôi tại
                                </Typography>

                                <Box height='20px'/>

                                <Typography
                                    variant='h4'
                                    fontWeight='bold'
                                    color='#473E3E'
                                >
                                    {calendarInviteContent === null ? '' : calendarInviteContent.venue}
                                </Typography>
                                <Typography>
                                    {calendarInviteContent === null ? '' : calendarInviteContent.venueDetail}
                                    <br/>
                                    {calendarInviteContent === null ? '' : calendarInviteContent.venueAddress}
                                </Typography>

                                <Box height='20px'/>

                                <Typography
                                    fontWeight='bold'
                                >
                                    {calendarInviteContent === null ? '' : calendarInviteContent.inviteTime}
                                </Typography>
                                <Typography>
                                    {calendarInviteContent === null ? '' : calendarInviteContent.inviteTime1}
                                </Typography>
                            </Stack>

                            {/** Styles and Souvenirs */}
                            {
                                calendarInviteContent !== null
                                    ? <Stack
                                        direction='row'
                                        marginTop='30px'
                                    >
                                        {/** Clothing style */}
                                        <Stack
                                            width='100%'
                                            alignItems={calendarInviteContent.inviteFor === 'bride' ? 'center' : 'left'}
                                            paddingX={calendarInviteContent.inviteFor === 'bride' ? '10px' : '0px'}
                                        >
                                            <Typography
                                                align={calendarInviteContent.inviteFor === 'bride' ? 'center' : 'left'}
                                                fontWeight={600}
                                            >
                                                Trang phục
                                            </Typography>
                                            <Typography
                                                align={calendarInviteContent.inviteFor === 'bride' ? 'center' : 'left'}
                                            >
                                                Màu sắc nhã nhặn. Tránh mặc màu trắng nhé!
                                            </Typography>
                                        </Stack>

                                        {/** Souvenir */}
                                        {
                                            calendarInviteContent.inviteFor === 'groom'
                                                ? null
                                                : <Stack
                                                    width='100%'
                                                    alignItems='center'
                                                >
                                                    <Typography
                                                        align='center'
                                                        fontWeight={600}
                                                    >
                                                        Quà lưu niệm
                                                    </Typography>
                                                    <Box
                                                        marginTop='8px'
                                                        width='56px'
                                                    >
                                                        <Image src={require('../../../../../assets/images/souvenir_icon.png')}/>
                                                    </Box>
                                                </Stack>
                                        }
                                    </Stack>
                                    : null
                            }

                            {/** Timeline */}
                            {
                                calendarInviteContent === null
                                    ? null
                                    : <Stack
                                        marginTop='30px'
                                    >
                                        <Typography
                                            fontWeight='bold'
                                        >
                                            Thời gian diễn ra
                                        </Typography>
                                        <Timeline position='alternate'>
                                            {
                                                calendarInviteContent.timeline.map((tl, index) =>
                                                    <CustomTimelineItem
                                                        key={index}
                                                        activity={tl.activity}
                                                        time={tl.time}
                                                        icon={tl.icon}
                                                    />,
                                                )
                                            }
                                        </Timeline>
                                    </Stack>
                            }

                            {/** Ending Text */}
                            <Stack
                                marginTop='30px'
                                width='100%'
                                alignItems='center'
                                paddingX='20px'
                            >
                                <Typography
                                    align='center'
                                >
                                    Sự hiện diện của bạn là niềm vinh dự của chúng tôi!
                                </Typography>
                            </Stack>
                        </Stack>

                        {/** Attendance Notice */}
                        <Stack
                            width='100%'
                            marginTop='70px'
                        >
                            {/** Response Headings */}
                            <Stack
                                width='100%'
                                alignItems='center'
                                paddingX='20px'
                            >
                                <Typography
                                    variant='h4'
                                    align='center'
                                    fontWeight={700}
                                    color='#473E3E'
                                >
                                    THÔNG TIN PHẢN HỒI
                                </Typography>
                                <Box marginTop='6px'/>
                                <Typography
                                    align='center'
                                >
                                    Để thuận tiện cho việc sắp xếp chỗ ngồi,
                                    vui lòng phản hồi giúp vợ chồng mình nhé!
                                </Typography>
                            </Stack>

                            {/** Attendance Notice Inputs */}
                            <Stack
                                width='100%'
                                paddingX='5%'
                                alignItems='center'
                                justifyContent='space-evenly'
                                component='form'
                            >
                                {/** Attend Notice Switch */}
                                <FormControl
                                    required
                                    fullWidth
                                    sx={{
                                        marginTop: '25px',
                                    }}
                                >
                                    <Stack
                                        direction='row'
                                        spacing='10px'
                                        width='100%'
                                        justifyContent='center'
                                        alignItems='center'
                                    >
                                        <Typography
                                            variant='h6'
                                            textAlign='center'
                                        >
                                            Từ chối
                                        </Typography>
                                        <CustomSwitch
                                            value={state.attendNotice.attend}
                                            checked={state.attendNotice.attend === 'yes'}
                                            inputProps={{'aria-label': 'Android 12 Switch Design'}}
                                            onChange={(e) => {
                                                dispatch(setAttend(e.target.checked ? 'yes' : 'no'))
                                            }}
                                        />
                                        <Typography
                                            variant='h6'
                                            textAlign='center'
                                        >
                                            Tham dự
                                        </Typography>
                                    </Stack>
                                </FormControl>

                                {/** Guest Name */}
                                <FormControl
                                    required
                                    fullWidth
                                    variant='standard'
                                    sx={{
                                        marginTop: '25px',
                                    }}
                                >
                                    <InputLabel
                                        shrink
                                        htmlFor='bootstrap-input'
                                        sx={{
                                            fontSize: '1.3rem',
                                            color: 'black',
                                            '&.Mui-focused': {
                                                color: 'black',
                                            },
                                        }}
                                    >
                                        Tên khách mời
                                    </InputLabel>
                                    <CustomTextField
                                        value={state.attendNotice.guestName}
                                        onChange={(e) => {
                                            dispatch(setGuestName(e.target.value))
                                        }}
                                    />
                                </FormControl>

                                {/** Guest Role */}
                                <FormControl
                                    required
                                    fullWidth
                                    variant='standard'
                                    sx={{
                                        marginTop: '25px',
                                    }}
                                >
                                    <CustomSelect
                                        labelId='guest-role'
                                        id='guest-role'
                                        disableUnderline
                                        value={state.attendNotice.guestRole}
                                        onChange={
                                            (e) =>
                                                dispatch(setGuestRole(e.target.value as ('none' | 'bride' | 'groom')))
                                        }
                                    >
                                        <MenuItem
                                            value='none'
                                        >
                                            Bạn là...
                                        </MenuItem>
                                        <MenuItem
                                            value='bride'
                                        >
                                            Bạn cô dâu
                                        </MenuItem>
                                        <MenuItem
                                            value='groom'
                                        >
                                            Bạn chú rể
                                        </MenuItem>
                                    </CustomSelect>
                                </FormControl>

                                {/** Number Of Guests */}
                                <FormControl
                                    required
                                    fullWidth
                                    variant='standard'
                                    sx={{
                                        marginTop: '25px',
                                    }}
                                >
                                    <CustomSelect
                                        labelId='no-of-guests-label'
                                        id='no-of-guests'
                                        disableUnderline
                                        value={state.attendNotice.numberOfGuests}
                                        onChange={
                                            (e) =>
                                                dispatch(setNumberOfGuests(Number(e.target.value) || 1))
                                        }
                                        disabled={state.attendNotice.attend === 'no'}
                                    >
                                        <MenuItem
                                            value={-1}
                                        >
                                            Số người tham dự
                                        </MenuItem>
                                        {
                                            state.attendNotice.attend === 'yes' ? null
                                                : <MenuItem
                                                    value={0}
                                                >
                                                    Không tham dự
                                                </MenuItem>
                                        }
                                        <MenuItem
                                            value={1}
                                        >
                                            1 người
                                        </MenuItem>
                                        <MenuItem
                                            value={2}
                                        >
                                            2 người
                                        </MenuItem>
                                        <MenuItem
                                            value={3}
                                        >
                                            3 người
                                        </MenuItem>
                                        <MenuItem
                                            value={4}
                                        >
                                            4 người
                                        </MenuItem>
                                        <MenuItem
                                            value={5}
                                        >
                                            5 người
                                        </MenuItem>
                                    </CustomSelect>
                                </FormControl>

                                {/** Submit Button */}
                                {
                                    calendarInviteContent === null
                                        ? null
                                        : <Box
                                            sx={{
                                                position: 'relative',
                                                marginTop: '50px',
                                            }}
                                        >
                                            <Button
                                                variant='contained'
                                                size='large'
                                                sx={{
                                                    color: 'black',
                                                    fontWeight: 600,
                                                }}
                                                disabled={state.attendNotice.guestName.trim().length === 0
                                                    || state.attendNotice.guestRole === 'none'
                                                    || state.attendNotice.numberOfGuests === -1}
                                                onClick={() => state.attendNotice.attendNoticeStatus === 'pending'
                                                    ? {} : dispatch(sendAttendNotice({
                                                        attend: state.attendNotice.attend,
                                                        attendFor: state.attendNotice.attend === 'yes'
                                                            ? calendarInviteContent.inviteFor
                                                            : null,
                                                        guestName: state.attendNotice.guestName.trim(),
                                                        guestRole: state.attendNotice.guestRole as ('bride' | 'groom'),
                                                        numberOfGuests: state.attendNotice.numberOfGuests,
                                                        wishes: state.attendNotice.wishes,
                                                    }))
                                                }
                                            >
                                                {
                                                    state.attendNotice.attendNoticeStatus === 'pending' ? 'Đang gửi ...' : 'Gửi phản hồi'
                                                }
                                            </Button>
                                        </Box>
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions
                    sx={{
                        margin: 0,
                        padding: 0,
                    }}
                >
                    {/** Close Button */}
                    <Fab
                        size='medium'
                        onClick={() => {
                            setCalendarInviteContent(null);
                            dispatch(resetAttendNotice());
                        }}
                        sx={{
                            margin: 0,
                            top: 'auto',
                            right: 20,
                            bottom: 30,
                            left: 'auto',
                            position: 'fixed',
                        }}
                    >
                        <Close/>
                    </Fab>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}

// Custom Switch
const CustomSwitch = styled(Switch)(({theme}) => ({
    width: 125,
    height: 40,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(85px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url(${require('../../../../../assets/images/smile_icon.png')})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#DAC6B7',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#DAC6B7',
        width: 38,
        height: 38,
        padding: 0,
        margin: 0,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundImage: `url(${require('../../../../../assets/images/sad_icon.png')})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#DAC6B7',
        borderRadius: 20,
    },
}));

const CustomTextField = styled(InputBase)(({theme}) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 10,
        position: 'relative',
        backgroundColor: '#DAC6B7',
        border: '1px solid #E7D7CD',
        fontSize: 17,
        width: '100%',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: 'black',
        },
    },
}));

const CustomSelect = styled(Select)(({theme}) => ({
    '& .MuiInputBase-input': {
        borderRadius: 10,
        position: 'relative',
        backgroundColor: '#DAC6B7',
        fontSize: 17,
        width: '100%',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'background-color',
            'box-shadow',
        ]),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: 'black',
        },
    },
}));

interface CustomTimelineItemProps {
    time: string;
    activity: string;
    icon: React.ReactNode;
}

const CustomTimelineItem = (props: CustomTimelineItemProps) => {
    const {time, activity, icon} = props;

    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineConnector sx={{backgroundColor: '#B1A79F'}}/>
                <TimelineDot sx={{backgroundColor: '#473E3E'}}>
                    {icon}
                </TimelineDot>
                <TimelineConnector sx={{backgroundColor: '#B1A79F'}}/>
            </TimelineSeparator>
            <TimelineContent sx={{m: 'auto 0'}}>
                <Typography
                    variant='body1'
                >
                    {activity}
                </Typography>
                <Typography
                    variant='body1'
                    color='text.secondary'
                >
                    {time}
                </Typography>
            </TimelineContent>
        </TimelineItem>
    );
}

const calendarInviteContents: CalendarInviteContent[] = [
    {
        inviteFor: 'bride',
        dateMonth: '27.12',
        year: '2022',
        venue: 'PHỐ ĐÔI GARDEN - THẢO NGUYÊN XANH',
        venueDetail: 'Khuôn viên',
        venueAddress: '118 Đường Bùi Hữu Nghĩa, Bửu Hoà, TP. Biên Hòa, Đồng Nai',
        inviteTime: 'Vào lúc 17h30 NGÀY 27 THÁNG 12 NĂM 2022',
        inviteTime1: 'Nhằm ngày 05 tháng 12 năm 2022 âm lịch',
        dressCode: ['#186B0E', '#EDDEBA'],
        timeline: [
            {
                time: '17:30',
                activity: 'Đón khách',
                icon: <Handshake/>,
            },
            {
                time: '18:30',
                activity: 'Làm lễ',
                icon: <Brightness7/>,
            },
            {
                time: '19:00',
                activity: 'Vào tiệc',
                icon: <Restaurant/>,
            },
        ],
    },
    {
        inviteFor: 'groom',
        dateMonth: '28.12',
        year: '2022',
        venue: 'NHÀ HÀNG HƯƠNG PHỐ',
        venueDetail: 'Sảnh D, Lầu 2',
        venueAddress: 'A12 Đ. Phan Văn Trị, Phường 7, Gò Vấp, TP. Hồ Chí Minh',
        inviteTime: 'Vào lúc 12h00 NGÀY 28 THÁNG 12 NĂM 2022',
        inviteTime1: 'Nhằm ngày 06 tháng 12 năm 2022 âm lịch',
        timeline: [
            {
                time: '12:00',
                activity: 'Đón khách',
                icon: <Handshake/>,
            },
            {
                time: '13:00',
                activity: 'Làm lễ',
                icon: <Brightness7/>,
            },
            {
                time: '13:30',
                activity: 'Vào tiệc',
                icon: <Restaurant/>,
            },
        ],
    },
];
