import {Grid, Paper} from '@mui/material';
import React from 'react';
import MainCover from './components/mainCover/MainCover';
import Invitation from './components/invitation/Invitation';
import EndNote from './components/endNote/EndNote';
import CalendarInvite from './components/calendarInvite/CalendarInvite';
import MobileBankingWish from './components/mobileBankingWish/MobileBankingWish';
import ThankYou from './components/thankyou/ThankYou';
import Venues from "./components/venues/Venues";

function InvitationCard() {
	return <Grid
		container
		justifyContent='center'
		bgcolor='#F5F5F5'
	>
		<Grid
			item
			sm={12}
			md={6}
			lg={4}
			width='100%'
		>
			<Paper
				elevation={4}
				sx={{
					backgroundColor: '#FFF',
				}}
			>
				{/** Main Cover */}
				<MainCover/>

				{/** Invitation */}
				<Invitation/>

				{/** Calendar Invite */}
				<CalendarInvite/>

				<Venues/>

				{/** Mobile Banking Wish */}
				<MobileBankingWish/>

				{/** Thank You */}
				<ThankYou/>

				{/** End Note */}
				<EndNote/>
			</Paper>
		</Grid>
	</Grid>;
}

export default InvitationCard;
